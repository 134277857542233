import Axios from "axios";
import Cookies from "js-cookie";

class BankReferrals {
  async getAllReferrals(pageNumber, totalCount, searchText) {
    const token = Cookies.get("jwt");
    const configAPi = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const url =
        process.env.REACT_APP_STAGE === "production"
          ? `${process.env.REACT_APP_API_BASE_URL}/bank-referrals/all`
          : `${process.env.REACT_APP_LOCAL_API_URL}/bank-referrals/all`;
      const r = await Axios.post(
        url,
        { pageNumber, totalCount, searchText },
        configAPi
      );
      return r.data;
    } catch (err) {
      throw err;
    }
  }
}

export default new BankReferrals();
